<div class="header">
    <h3 mat-dialog-title>Details: {{filterLabel}}</h3>
    <button mat-button color="primary" [disabled]="loadingData ||  faultList.length == 0"  (click)="downloadAsCSV()"><mat-icon>download</mat-icon> Download</button>
</div>

<div mat-dialog-content> <!-- style="overflow: auto;" -->
    <div *ngIf="!loadingData">
          <mat-table [dataSource]="faultList">

            <ng-container matColumnDef="ShiftDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Shift Date</mat-header-cell>
                <mat-cell *matCellDef="let d">
                    <div class="fx-column-start-start">
                        <div>
                            <div class="dataHeader">Shift Date</div>
                            <div class="dataValue">{{d.ShiftDate | date:'MM/dd/yyyy'}}</div>
                        </div>
                        <div>
                            <div class="dataHeader">Shift</div>
                            <div class="dataValue"> {{d.Shift}}</div>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Hour">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Hour</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.Hour}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="FaultTime">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Fault Time</mat-header-cell>
                <mat-cell *matCellDef="let d">
                    <div class="fx-column-start-start">
                        <div>
                            <div class="dataHeader">Start</div>
                            <div class="dataValue">{{d.StartTime | date:'MM/dd/yyyy HH:mm:ss'}}</div>
                        </div>
                        <div>
                            <div class="dataHeader">End</div>
                            <div class="dataValue">{{d.EndTime | date:'MM/dd/yyyy HH:mm:ss'}}</div>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
   
            <ng-container matColumnDef="Downtime">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Duration</mat-header-cell>
                <mat-cell *matCellDef="let d">{{formatDuration(d.Downtime)}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="FaultMessage">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Fault</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.FaultMessage}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="RootCause">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Root Cause</mat-header-cell>
                <mat-cell *matCellDef="let d">
                    <div class="fx-column-start-start">
                        <div>
                            <div class="dataHeader">Level 1</div>
                            <div class="dataValue">{{d.RootCause_Level1}}</div>
                        </div>
                        <div *ngIf="d.RootCause_Level2 && d.RootCause_Level2.trim() != ''">
                            <div class="dataHeader">Level 2</div>
                            <div class="dataValue">{{d.RootCause_Level2}}</div>
                        </div>
                        <div *ngIf="d.RootCause_Level3 && d.RootCause_Level3.trim() != ''">
                            <div class="dataHeader">Level 3</div>
                            <div class="dataValue" >{{d.RootCause_Level3}}</div>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Notes">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Comment</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.Notes}}</mat-cell>
            </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>          
          </mat-table>
    </div>
    <div *ngIf="loadingData">
          <mat-spinner>Loading...</mat-spinner>
    </div>
</div>

<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" (click)="close()">Close</button>
</div>
<app-button-bar [site]="site" [objectid]="objectid"></app-button-bar>
<div style="padding: 15px">
    <app-metrics-header [object]="object"></app-metrics-header>
    <div class="fx-row-space-between-start hide-mobile"> 
        <div><button mat-raised-button color="primary" *ngIf="manualEntry" (click)="manualProblemEntry()">Manual Problem</button></div>

        <div><button mat-raised-button color="primary" (click)="openHourNote()">
            <span>Update Hour Notes</span>
        </button></div>

        <div>
            <button mat-raised-button color="primary" (click)="openFaultDetails()">Fault Details</button>
        </div>

        <div><button mat-raised-button color="primary" (click)="toggleEditMode()" [disabled]="!faultListCurrent || editStatus == ChangeStatusEnum.CHANGES_INVALID">
            <span *ngIf="!editMode">Enter Edit Mode</span>
            <span *ngIf="editMode && editStatus != ChangeStatusEnum.CHANGES_VALID">Enter View Mode</span>
            <span *ngIf="editMode && editStatus == ChangeStatusEnum.CHANGES_VALID">Save All and Enter View Mode</span>
        </button></div>

        <!--TODO: AGK Probably eliminate this, with the new combined options don't know if this makes sense any longer <div><button mat-raised-button color="primary" (click)="filter()">
            <span *ngIf="minDuration == 0">No Filter</span>
            <span *ngIf="minDuration != 0">Filter {{minDuration}} seconds</span>
        </button></div>-->
    </div>
    <mat-card  class="metrics-card mat-elevation-z1">
        <mat-card-title class="metric-title">
                Details: 
                    <span *ngIf="!showAllHours"> Hour {{problemsData.Hour}} {{problemsData.Start}} - {{problemsData.End}}</span>
                    <span *ngIf="showAllHours"> All Hours </span>
        </mat-card-title>
        <mat-card-content>

            <mat-table [dataSource]="faultListData">

                <ng-container matColumnDef="Hour">
                    <mat-header-cell *matHeaderCellDef>Hour</mat-header-cell>
                    <mat-cell *matCellDef="let d">{{d.Hour}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Fault">
                    <mat-header-cell *matHeaderCellDef>Fault Value</mat-header-cell>
                    <mat-cell *matCellDef="let d">{{d.FaultMessage}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Duration">
                    <mat-header-cell *matHeaderCellDef>Total Duration</mat-header-cell>
                    <mat-cell *matCellDef="let d">{{formatDuration(d.Problem_time_amt)}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="LostUnits">
                    <mat-header-cell *matHeaderCellDef><span [matTooltip]="'Total Duration / Ideal Cycle Time (' + cycleTime.toFixed(2) + ' seconds)'">Lost Units</span></mat-header-cell>
                    <mat-cell *matCellDef="let d"><span *ngIf="cycleTime !=0">{{getLostUnits(d.Problem_time_amt)}}</span></mat-cell>
                </ng-container>

                <ng-container matColumnDef="Occurrences">
                    <mat-header-cell *matHeaderCellDef>Occurrences</mat-header-cell>
                    <mat-cell *matCellDef="let d">{{d.Occurrences}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Notes">
                    <mat-header-cell *matHeaderCellDef>Root Cause/Corrective Action</mat-header-cell>
                    <mat-cell *matCellDef="let d; let idx = index;">
                        <app-problem-entry *ngIf="editMode" class="test" [problemData]="d" [index]="idx" [showTeam]="config.showTeam" (savedFaultEntry) = "toggleEditMode()"></app-problem-entry>
                        <div *ngIf="!editMode && d.Problem != 'SP'" class="fx-row-space-between-start">
                            <div style="min-width: 200px;" class="fx-column-start-start">
                                <div *ngIf="config.showTeam">
                                    <div class="rootCauseHeader">Team</div>
                                    <div class="rootCauseValue">
                                        <span *ngIf="d.Team || d.Team.trim() != ''">{{d.Team}}</span>
                                        <span *ngIf="!d.Team || d.Team.trim() == ''">--</span>
                                    </div>
                                </div>
                                <div>
                                    <div class="rootCauseHeader">Level 1</div>
                                    <div class="rootCauseValue">{{d.Problem}}</div>
                                </div>
                                <div *ngIf="d.Source != 'PLANNED_DT'">
                                    <div class="rootCauseHeader">Level 2</div>
                                    <div class="rootCauseValue">
                                        <span *ngIf="d.Problem_Level2 && d.Problem_Level2.trim() != ''">{{d.Problem_Level2}}</span>
                                        <span *ngIf="!d.Problem_Level2 || d.Problem_Level2.trim() == ''">--</span>
                                    </div>
                                    
                                </div>
                                <div *ngIf="d.Source != 'PLANNED_DT'">
                                    <div class="rootCauseHeader">Level 3</div>
                                    <div class="rootCauseValue">
                                        <span *ngIf="d.Problem_Level3 && d.Problem_Level3.trim() != ''">{{d.Problem_Level3}}</span>
                                        <span *ngIf="!d.Problem_Level3 || d.Problem_Level3.trim() == ''">--</span>
                                    </div>
                                </div>
                            </div>
                            <div class="rootCauseValue">
                                {{d.Notes}}
                            </div>
                        </div>
                        <div *ngIf="!editMode && d.Problem == 'SP'" class="rootCauseNone">
                            Not entered.
                        </div>
                    </mat-cell>
                </ng-container>
        
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card-content>
    </mat-card>
</div>
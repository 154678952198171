import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AnalysisService } from '../../analysis-service.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-fault-details-dialog',
  templateUrl: './fault-details-dialog.component.html',
  styleUrls: ['./fault-details-dialog.component.scss']
})
export class FaultDetailsDialog implements OnInit {

  faultListSub$: Subscription;
  faultList:any[];
  loadingData:boolean=true;

  filterLabel:string =''

  displayedColumns: string[] = ['ShiftDate','FaultTime','Hour','Downtime','FaultMessage', 'RootCause', 'Notes']; // List of columns

  constructor(
    public dialogRef: MatDialogRef<FaultDetailsDialog>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public inData: FaultDetailInput,
    private svcAnalysis: AnalysisService,
  ) { 
    // Set defaults for optional parameters
    (!this.inData.level1) ? this.inData.level1 = "" : null;
    (!this.inData.level2) ? this.inData.level2 = "" : null;
    (!this.inData.level3) ? this.inData.level3 = "" : null;
    (!this.inData.hour) ? this.inData.hour = "ALL" : null;
  }

  ngOnInit(): void {
    console.log("In Data", this.inData);
    // Create the title label

    if (this.inData.hour != 'ALL') {
      this.filterLabel = `Hour ${this.inData.hour}`;
    } else {
      this.filterLabel = (this.inData.level1 != '') ? this.inData.level1 : 'All';
      this.filterLabel += (this.inData.level2 != '') ? ` -> ${this.inData.level2}` : '';
      this.filterLabel += (this.inData.level3 != '') ? ` -> ${this.inData.level3}` : '';
    }

    this.getFaultList();
  }

  formatDuration(seconds:number) {
    return new Date(seconds * 1000).toISOString().slice(11, 19);
  }

  getFaultList(): void {
    if (this.faultListSub$) this.faultListSub$.unsubscribe();

    this.faultListSub$ = this.svcAnalysis.faultList(this.inData.site, this.inData.environment, this.inData.objectid, this.inData.startDate, this.inData.endDate, this.inData.shifts.toString(), this.inData.level1, this.inData.level2, this.inData.level3, this.inData.hour).subscribe(
      out => {
        // Check if we got a return, possible to get a null due to caching in the service
        if (out) {
          this.loadingData=false;
          
          this.faultList = out.Body;
          
        }
      }
    );
  }

  /**
   * Converts rawData to a CSV (using displayedColumns as header) and downloads to the users computer
   */
  downloadAsCSV() {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(this.faultList[0]);
    let csv = this.faultList.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    // Build the filename
    let fileName:string = `FAULT DETAILS ${this.inData.objectid}`;

    if (this.inData.startDate != this.inData.endDate) {
      fileName += ` ${this.inData.startDate} to ${this.inData.endDate}`;
    } else {
      fileName += ` SHIFT ${this.inData.startDate} ${this.inData.shifts.toString()} HOUR ${this.inData.hour}`;
    }
    
    fileName += ".csv";

    saveAs(blob, fileName);
}

  close(): void {
    this.dialogRef.close({});
  }

}

export interface FaultDetailInput {
  site: string;
  environment: string;
  objectid: string;
  startDate: string;
  endDate: string;
  hour?: string;
  shifts: string[];
  level1?: string;
  level2?: string;
  level3?: string;
}